import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './homepage/homepage.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AnnouncementsAndPrayersComponent } from './announcements-and-prayers/announcements-and-prayers.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { GivingComponent } from './giving/giving.component';
import { AboutComponent } from './about/about.component';
import { SundayMessageComponent } from './sunday-message/sunday-message.component';

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    HeaderComponent,
    FooterComponent,
    AnnouncementsAndPrayersComponent,
    ContactUsComponent,
    GivingComponent,
    AboutComponent,
    SundayMessageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
