import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sunday-message',
  templateUrl: './sunday-message.component.html',
  styleUrls: ['./sunday-message.component.scss']
})
export class SundayMessageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
