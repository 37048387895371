import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { AboutComponent } from '../about/about.component';
import { AnnouncementsAndPrayersComponent }  from '../announcements-and-prayers/announcements-and-prayers.component';
import { GivingComponent } from '../giving/giving.component';
import { FooterComponent } from '../footer/footer.component';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
