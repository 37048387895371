import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-announcements-and-prayers',
  templateUrl: './announcements-and-prayers.component.html',
  styleUrls: ['./announcements-and-prayers.component.scss']
})
export class AnnouncementsAndPrayersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
